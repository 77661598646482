import React from 'react'
import tw from 'twin.macro'

import SGALogo from '../logos/SGALogo'

const Footer = () => (
  <footer css={tw`bg-gray-900 flex flex-col items-center`}>
    <div
      css={tw`container text-center text-gray-300 text-lg font-semibold pt-4`}>
      © {new Date().getFullYear()} Sherry&apos;s Cafe Trussville, Sherry&apos;s
      Inc.
    </div>
    <a href='https://shakeyg.com' target='_blank' rel='noreferrer'>
      <div css={tw`pt-8 pb-8 w-40`}>
        <SGALogo />
      </div>
    </a>
  </footer>
)

export default Footer
