import React from 'react'
import tw from 'twin.macro'

import MenuItem from './MenuItem'

const Menu = () => (
  <div css={tw`flex pt-3`}>
    <div
      css={tw`w-full sm:flex justify-center sm:justify-start lg:items-center lg:w-auto`}>
      <div css={tw`text-sm lg:flex-grow justify-between flex`}>
        <MenuItem to='/'>Home</MenuItem>
        <MenuItem to='/menu'>Menu</MenuItem>
        <MenuItem to='/catering'>Catering</MenuItem>
      </div>
    </div>
  </div>
)

export default Menu
