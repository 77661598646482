import React from 'react'
import { Link } from 'gatsby'
import tw from 'twin.macro'

import MenuRight from '../navigation/MenuRight'
import Menu from '../navigation/Menu'

const Header = () => (
  <header css={tw`pt-6`}>
    <div css={tw`pt-6 pb-6 border-dashed border-b`}>
      <div
        css={tw`container flex flex-col md:flex-row justify-between items-center`}>
        <div css={tw`flex-grow`}>
          <h1 css={tw`font-heading text-4xl font-semibold`}>
            <Link to='/'>Sherry&apos;s Cafe</Link>
          </h1>
        </div>
        <MenuRight />
      </div>
    </div>
    <div css={tw`container`}>
      <Menu />
    </div>
  </header>
)

export default Header
