import React from 'react'
import { Link } from 'gatsby'
import tw from 'twin.macro'

import H2 from '../styled/H2'

const MenuItem = ({ children, to }) => (
  <div css={tw`block mt-4 lg:mt-0 sm:mr-4`}>
    <Link
      to={to}
      css={tw`block`}
      activeStyle={tw`border-solid border-b-2 border-red-800 font-semibold`}>
      <H2 css={tw`text-sm pb-2`}>{children}</H2>
    </Link>
  </div>
)

export default MenuItem
