import React from 'react'
import tw from 'twin.macro'

import FBLogo from '../logos/FBLogo'

const MenuRight = () => (
  <div css={tw`flex flex-col-reverse sm:flex-row items-center justify-center`}>
    <div css={tw`sm:pr-10 w-32 lg:w-40`}>
      <a
        href='https://www.facebook.com/SherrysCafe/'
        target='_blank'
        rel='noreferrer'>
        <FBLogo />
      </a>
    </div>
    <div css={tw`sm:pr-10 pb-8 sm:pb-0 text-xl`}>
      📱{' '}
      <a href='tel:2056555260' css={tw`text-blue-600 underline`}>
        <span itemProp='telephone' content='+12056555260'>
          (205) 655-5260
        </span>
      </a>
    </div>
    <div css={tw`pb-8 sm:pb-0 pt-8 sm:pt-0`}>
      <a
        href='https://order.sherryscafetrussville.com'
        target='_blank'
        rel='noreferrer'>
        <div
          css={tw`bg-red-800 rounded-lg text-white pl-4 pr-4 pt-2 pb-2 text-center text-base font-semibold align-baseline`}>
          Order Online
        </div>
      </a>
    </div>
  </div>
)

export default MenuRight
